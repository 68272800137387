@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Ubuntu';
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

@keyframes fadeInDown {
  from {
    opacity: 0;

  }

  to {
    opacity: 1;

  }
}

.animate-fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  transform-origin: top;
}

.animate-up {
  animation-name: aparecer;
  animation-duration: 0.5s;
  transform-origin: bottom;
  overflow: hidden;
}

.over {
  overflow: hidden;
}

@keyframes aparecer {
  0% {
    transform: translateY(100%);
    /* Comienza arriba de la pantalla */
    opacity: 0;
    /* Inicialmente invisible */
  }

  100% {
    transform: translateY(0);
    /* Termina en su posición normal */
    opacity: 1;
    /* Totalmente visible */
  }
}

.inputs-holder {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

input {
  outline: none !important;
}

input:focus {
  border-color: #cfcfcf;
}

@media (max-width: 780px) {
  .inputs-holder {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .inputs-holder div {
    width: 100% !important;
  }

  .inputs-holder div input {
    font-size: 14px;
  }
}